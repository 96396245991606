import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "PLAN";

export default class Plan extends Model {
  // Attributes
  nickname: string = "";
  description: string = "";
  amount: number = 0;
  currency: string = "";
  interval: string = "";
  intervalCount: number = 30;
  variantDescription: string = "";
  variantContents: string = "";
  billingType: string = "";
  inStock: boolean = true;
  soldIndividually: boolean = false;

  // Relationships
  productId: string = ""; // BelongsTo
  storeId: string = "";

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);

    const relationships = input.relationships;
    this.productId = this.getRelationshipId(relationships, "product");
    this.storeId = this.getRelationshipId(relationships, "store");
  }
}
