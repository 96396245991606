import Promotion, { ModelType } from "./model";
import normalizedActions from "../utils/normalized/actions";
import fetchInternal from "../../utils/fetch";
import siteStore from "../../services/siteStore";
import { UPDATE_BEST_ELIGIBLE_PROMOTION_ID } from "./reducer";
import fetch from "../utils/normalized/fetch";

export function addPromotionsFromGraphQL(promotionData) {
  return (dispatch) => {
    promotionData.forEach((data) => {
      const promotion = new Promotion();
      promotion.deserialize(data);
      dispatch(addPromotion(promotion));
    });
  };
}

export function removePromotion(id) {
  return (dispatch) => {
    return normalizedActions.removeModel(dispatch, ModelType, id);
  };
}

export function addPromotionFromNormalizedData(data, associations) {
  return (dispatch) => {
    const promotion = new Promotion();
    promotion.deserialize(data);
    dispatch(addPromotion(promotion, associations));
  };
}

export function addPromotion(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().promotion,
      ModelType,
      data,
      associations,
    );
  };
}

export function refreshBestEligiblePromotion() {
  return async (dispatch, getState) => {
    const params = new URLSearchParams({
      store_id: siteStore.storeId,
    }).toString();

    const response = await fetchInternal(`promotions/best_eligible?${params}`, {
      method: "GET",
      credentials: "include",
    });

    if (!response) {
      return dispatch({
        type: UPDATE_BEST_ELIGIBLE_PROMOTION_ID,
        payload: {
          bestEligiblePromotionId: undefined,
        },
      });
    }

    const promotion = new Promotion();
    promotion.deserialize(response.data);

    dispatch({
      type: UPDATE_BEST_ELIGIBLE_PROMOTION_ID,
      payload: {
        bestEligiblePromotionId: promotion.id,
      },
    });

    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().promotion,
      ModelType,
      promotion,
    );
  };
}

export function fetchPromotions() {
  return async (dispatch) => {
    return fetch(`promotions/active`, {}, dispatch, ModelType).catch(
      (error) => {
        console.error(error);
      },
    );
  };
}
